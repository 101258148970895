import React from 'react';
import '../App.css'; // Import styles if necessary

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Add your other footer content here */}
        <div className="hippa-logo-container">
          <img src="src/assets/hippa.webp" alt="HIPAA Compliant Logo" className="hippa-logo" />
        </div>
      </div>
    </footer>
  );
}

export default Footer; // Export the Footer component
