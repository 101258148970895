import React from 'react';
import '../App.css';
import Login from '../components/Login';
import Footer from '../components/Footer'

function Home() {
  return (
    <div className="home-background">
      <div className="center-container">
        <h1 className="title-text">
          Welcome to <span className="brand-name">WealthHex.io</span>
        </h1>
        <p className="description-text">
          WealthHex is a dynamic ecosystem of interconnected brands delivering transformative solutions in AI, customer engagement, and business intelligence. Together, we empower businesses to unlock their full potential.
        </p>
        <div className="brands-container">

          <h2 className="section-title">Our Commitment to Security</h2>
          <p className="description-text">
            At WealthHex, we understand the importance of data privacy and security. That's why our entire platform is <strong>HIPAA certified</strong>, ensuring compliance with the highest standards for industries like healthcare and beyond.
          </p>
          <h2 className="section-title">Our Brands:</h2>
          <ul className="brand-list">
            <li>
              <a href="http://whisperaiml.com" className="brand-link">Whisper AiML</a> — A cutting-edge AI-driven platform delivering real-time transcription and translation capabilities. By feeding the latest data into our intelligent bots, Whisper AiML ensures unparalleled accuracy and relevance in customer interactions and technical support, enabling informed decision-making and seamless user experiences.
            </li>
            <li>
              <a href="http://chatwithmywebsite.com" className="brand-link">Chat with My Website</a> — An intuitive entry-level business chatbot designed to streamline customer engagement. Perfect for businesses looking to enhance their online presence, this solution provides an easy-to-deploy, intelligent assistant to handle customer queries efficiently, ensuring consistent and reliable support for growing enterprises.
            </li>
            <li>
              <a href="http://embedgpt.chatwithaiml.com" className="brand-link">EmbedGPT</a> — A fully deployable Shopify app designed to revolutionize e-commerce operations. With seamless GraphQL integration for real-time updates and advanced PostgreSQL vector databases, EmbedGPT delivers unparalleled performance and efficiency. This all-in-one solution empowers businesses to enhance customer experiences, optimize workflows, and stay ahead in the competitive e-commerce landscape with minimal effort.
            </li>
            <li>
              <a href="http://signalaiml.com" className="brand-link">Signal AiML</a> — A comprehensive communication platform offering seamless integration of push notifications, email, SMS, and call center connectivity. Designed to streamline customer engagement and operational efficiency, Signal AiML ensures businesses can deliver timely, actionable messages across multiple channels, empowering teams to stay connected and responsive to customer needs.
            </li>
            <li>
             <a href="http://chatgptaiml.com" className="brand-link">ChatGPT AiML</a> — Our general intelligence, free AI chatbot designed to provide seamless interactions and deliver intelligent responses for all users.
            </li>
            <li>
              <em>Coming Soon:</em> <strong>howdoi.pro</strong> and <strong>howdoi.app</strong> — AI-enhanced productivity solutions.
            </li>
          </ul>
        </div>
        {/* Aqua Restore Pro Live Example */}
        <div className="live-example-container">
          <h2 className="section-title">A Live Example of Our Work</h2>
          <p className="description-text">
            At <strong>WealthHex</strong>, we don't just deliver cutting-edge solutions — we implement them to drive measurable results for our partners. One such success story is 
            <a href="http://aquarestorepro.com" className="brand-link"> Aqua Restore Pro</a>, where we handle their entire business ecosystem, including:
          </p>
          <ul className="service-list">
            <li>Scheduling and call center operations</li>
            <li>Customer communications (email, SMS, push notifications)</li>
            <li>Comprehensive website design and management</li>
            <li>Online advertising and Facebook page management</li>
            <li>End-to-end marketing and lead generation</li>
          </ul>
          <p className="description-text">
            By entrusting WealthHex with their business processes, Aqua Restore Pro has streamlined operations, enhanced customer satisfaction, and achieved consistent growth in their market.
          </p>
        </div>

        {/* Login Section */}
        <div className="login-container">
          <h2 className="section-title">Experience the Platform</h2>
          <p className="login-prompt">Please log in to explore our ecosystem:</p>
          <Login />
        </div>
 {/* Footer Section */}
 <Footer /> {/* Render the Footer here */}
      </div>
    </div>
  );
}

export default Home;
